.socialIconsAbout{
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.followMe{
    display: flex;
    align-items: center;
    gap: 1rem;

}


.followMe p{
    font-size: 13px;
    color: grey;
}


