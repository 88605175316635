

.container{
    background-color: #fafafa;
    
}

.whiteContainer{
    width: 80%;
    margin: auto;
    background-color: white;
    padding: 50px 
}

.desHeader{
    font-size: 35px;
    font-weight: 700;
    width: 70%;
    margin-top: 10px;
}

.desPara{
    width: 70%;
    line-height: 1.5;
}

.descriptionFlex{
    display: flex;
    justify-content: center;
    gap: 1rem;
    /* margin-top: 30px; */
    line-height: 1;
    padding: 20px;
    align-items: center;
}


.profileBig{
    /* width: 100%; */
    height: 400px;
    border-radius: 10px;
    object-fit:cover;
    transform: rotate(4deg);
}


.profileImg {
    position: absolute;
    top: 80px; 
    transition: top 0.6s ease;
}

.profileImg.sticky {
    position: fixed;
    top: 20px;
    width: 50px;
    height: 50px;
    border: black solid 1px;
}


.profileImg{
    border-radius: 50px;
    width: 80px;
    height: 80px;
}

.desHeader h2 {
    margin-top: 10px;
}


.socialIcons{
    display: flex;
    gap: 1rem;
}

.skillSingle {
    width: 300px;
    text-align: left;
    padding: 20px;
    margin-top: 40px;
    transition: background-color 0.3s ease;
}

.skillSingle:hover {
    background-color: #f0f0f0;
    border-radius: 20px;
}


.skillDiv{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1rem;
}


.seeMoreButton {
    position: relative;
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.seeMoreButton::before,
.seeMoreButton::after {
    content: '';
    position: absolute;
    top: 50%;
    width: 30px;
    height: 1px;
    background-color: #007bff;
}

.seeMoreButton::before {
    left: -40px;
}

.seeMoreButton::after {
    right: -40px;
}

.seeMoreButton:hover {
    background-color: #0056b3;
}
