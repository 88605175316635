

.nav {
    margin: 20px;
    position: fixed;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); 
    width: 450px;
    background-color: white;
    border-radius: 50px;
    
}

.navigation{
    display: flex;
    justify-content: center;
    align-items: center;
}

.navItem {
    display: flex;
    gap: 1rem;
    color: white;
}

.nav p {
    font-size: 13px;
}


.nav p:hover{
    color: burlywood;
    cursor: pointer;
}